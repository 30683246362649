<template>
  <base-modal name="statusPagePassword"
              width="40%"
              height="auto">
    <template #header>
      {{ headerText }}
    </template>

    <div>
      <form @submit.prevent="handleSubmit()">
        <div class="d-flex justify-content-center mt-3 mx-5">
          <base-textbox :placeholder="passwordPlaceholder" type="password"
                        v-model="password" class="mx-2 w-100"/>
          <base-button type="submit" v-bind:disabled="!valid" :loading="saving">{{ submitButtonText }}</base-button>
        </div>
        <base-alert v-if="error"
                    type="error">Something went wrong.</base-alert>
      </form>
    </div>

    <template #footer>
      <base-button type="button"
                   color="danger"
                   :outlined="true"
                   @click="$modal.hide('statusPagePassword')">{{ cancelButtonText }}</base-button>
    </template>

  </base-modal>
</template>

<script>
import statusPagesApi from '@/api/statusPagesApi'

export default {
  name: 'statusPagePassword',
  props: {
    statusPagePasswordError: {
      type: Boolean
    },
    slug: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      password: '',
      saving: false,
      error: null,
      statusPageLanguage: {}
    }
  },

  created () {
    this.loadStatusPageLanguage()
  },

  computed: {
    valid () {
      return this.password.length > 0
    },

    headerText () {
      return this.statusPageLanguage.text_password_header ?? 'Private page'
    },

    passwordPlaceholder () {
      return this.statusPageLanguage.text_password_input_placeholder ?? 'Password'
    },

    submitButtonText () {
      return this.statusPageLanguage.text_password_button_submit ?? 'Submit'
    },

    cancelButtonText () {
      return this.statusPageLanguage.text_password_button_cancel ?? 'Cancel'
    }
  },

  methods: {
    handleSubmit () {
      this.$emit('loadPage', this.password)
      this.saving = true
    },

    async loadStatusPageLanguage () {
      const response = await statusPagesApi.getPasswordTranslation(this.slug ?? window.location.hostname)
      if (response?.json_data) {
        this.statusPageLanguage = JSON.parse(response.json_data)
      }
    }
  },

  watch: {
    statusPagePasswordError () {
      if (this.saving === true) {
        this.error = true
        this.saving = false
      }
    }
  }
}
</script>
